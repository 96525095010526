import CollageComponent from "../../core/components/collage"
import withProps from "../../core/composers/props"
import useImagesDirect from "../../client/queries/images-direct"

/**
 * Compose
 */
const Overlap = withProps(
  CollageComponent,
  (props) => {
    /** Get number from a css number string */
    const getNumber = (string) => {
      let number = NaN
      let unit = ""

      if (typeof string === "number") {
        number = string
      } else {
        let match =
          string.match(
            /([\d]+)(cm|mm|in|px|pt|pc|em|ex|ch|rem|vw|vh|vmin|vmax|%)/
          ) || []

        if (match) {
          number = parseInt(match[1], 10)
          unit = match[2]
        }
      }

      return [number, unit]
    }

    /** Double a CSS number with unit */
    const doubleString = (string) => {
      const [number, unit] = getNumber(string)
      return `${number * 2}${unit}`
    }

    // Double cell sizes
    props.sizes = props.sizes.map((sizes) => ({
      w: doubleString(sizes.w),
      h: doubleString(sizes.h),
    }))

    /** Align overlap cells */
    switch (props.position) {
      default:
      case "top left":
        props.positions[0].x = 0
        props.positions[0].y = 0
        props.positions[0].justify = "start"
        props.positions[0].align = "start"
        props.positions[1].x = 1
        props.positions[1].y = 1
        props.positions[1].justify = "end"
        props.positions[1].align = "end"
        break

      case "top right":
        props.positions[0].x = 1
        props.positions[0].y = 0
        props.positions[0].justify = "end"
        props.positions[0].align = "start"
        props.positions[1].x = 0
        props.positions[1].y = 1
        props.positions[1].justify = "start"
        props.positions[1].align = "end"
        break
    }

    delete props.position

    return props
  },
  {
    className: "overlap",
    width: 2,
    height: 2,
    positions: [
      {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
        h: 1,
        align: "start",
        justify: "start",
      },
      { x: 1, y: 1, z: 1, w: 1, h: 1, align: "end", justify: "end" },
    ],
  }
)

export default Overlap
