import React from "react"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import ContactUs from "../../client/compositions/contact-us"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import { ProductsTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"
import Button from "../../client/compositions/button"

/**
 * Product: Custom Build Page
 * @see https://zpl.io/2GKpOWm
 */
function ProductCustomBuildPage() {
  return (
    <article
      id="page-product-custom-build"
      className="page-content product-page"
    >
      {quickMenu}

      <Banner
        title={
          <>
            Custom
            <br />
            Build
          </>
        }
        content={
          <>
            <p>
              Invinity Wine Racks are designed as the pairing of functionality
              and aesthetic beauty, with a focus on having the modularity and
              versatility to realize any wine display vision.
              <br />
              <br />
              As part of Invinity’s commitment to actualize your wine display
              vision into a wine display reality, Invinity Wine Racks have
              limitless design capabilities to take display versatility to a new
              level.
            </p>
            <Button image="icons/arrow-right.svg" to="/contact">
              Start your project
            </Button>
          </>
        }
        className="flipped custom-build-banner"
      >
        <DecorationFluid image="product-custom-build/banner.jpg" />
      </Banner>

      <Row id="height" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title">Customization Criteria</h2>
          <div className="content">
            Rack Dimensions
            <br />
            • Height
            <br />
            • Width
            <br />
            Project Layout
            <br />
            • Racking Styles
            <br />
            • Combining Racks
            <br />
            Metal Finishes
            <br />
          </div>
        </Block>
        <Block className="block-media">
          <Overlap
            style={{
              width: "46vw",
              height: "51vw",
            }}
            sizes={[
              { w: "100%", h: "70%" },
              { w: "41%", h: "48%" },
            ]}
            styles={[{}, { transform: "translate(-40px, 0px)" }]}
            className="overlap not-mobile-only"
          >
            <DecorationFluid image="product-custom-build/height-01.medium.jpg" />
            <DecorationFluid image="product-custom-build/height-02.small.jpg" />
          </Overlap>
          <Overlap
            style={{
              width: "46vw",
              height: "51vw",
              marginBottom: "110px",
            }}
            sizes={[
              { w: "100%", h: "70%" },
              { w: "75%", h: "80%" },
            ]}
            styles={[{}, { transform: "translate(0px, 110px)" }]}
            className="overlap mobile-only"
          >
            <DecorationFluid image="product-custom-build/height-01.medium.jpg" />
            <DecorationFluid image="product-custom-build/height-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Row id="style" className="content-plus-media flipped">
        <Block className="block-content">
          <h2 className="title">Form</h2>
          <div className="content">
            Our design specialists are standing by to assist you in turning your
            wine cellar dream to a wine cellar reality. Provide us the
            measurements of the space and a brief decription of your project
            idea, and we'll provide you with a detailed 2D Floor Plan, an
            Elevation Drawing, and a no-commitment Itemized Budget Estimate.
          </div>
        </Block>
        <Block className="block-media">
          <Overlap
            position="top right"
            style={{
              width: "44vw",
              height: "48vw",
              paddingBottom: "70px",
            }}
            sizes={[
              { w: "70%", h: "90%" },
              { w: "50%", h: "55%" },
            ]}
            styles={[{}, { transform: `translateY(70px)` }]}
          >
            <DecorationFluid image="product-custom-build/style-01.medium.jpg" />
            <DecorationFluid image="product-custom-build/style-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Row id="finishes" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title">Limitless Customization</h2>
          <div className="content">
            Here we move onto Winetrend and the legacy and capacity of that
            compeny.
          </div>
        </Block>
        <Block className="block-media">
          <Overlap
            style={{
              width: "48vw",
              height: "48vw",
              marginBottom: "80px",
            }}
            sizes={[
              { w: "70%", h: "88%" },
              { w: "55%", h: "53%" },
            ]}
            styles={[{}, { transform: `translateY(40px)` }]}
          >
            <DecorationFluid image="product-custom-build/finishes-01.medium.jpg" />
            <DecorationFluid image="product-custom-build/finishes-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <ContactUs>
        <DecorationFluid image="/product-custom-build/contact.small.jpg" />
      </ContactUs>
      <ProductsTaglineMenu productSlug="product-custom-build" />
    </article>
  )
}

export default ProductCustomBuildPage
